
import {get,post} from "@/axios";

//分组页面
export function trafficStatistics(data) {
    return get('api/trafficStatistics/index',data);
}

//访问页面
export function historyList(data) {
    return post('api/trafficStatistics/historyList',data);
}

export function editRemark(data) {
    return post('/api/sites/editRemark',data);
}


